const En = {
    btn: {
        saveName: 'I-save ang pangalan',
        yes: 'Oo',
        no: 'Hindi',
        add: 'Idagdag',
        viewCart: 'Tingnan ang aking cart',
        alertOk: 'Naiintindihan ko',
        confirmOrder: 'Kumpirmahin ang iyong order',
        shopping: `Mag-shopping tayo!`,
        status: {
            orderStatus0: 'I-refresh ang status ng order',
            orderStatus1: 'Tingnan ang status ng delivery',
            orderStatus9: 'Mag-order sa ibang mga merchant'
        }
    },
    text: {
        info: 'Tandaan',
        nonPartnerInfo: 'Hindi partner ang merchant na ito',
        saveName: 'Hello. Bago magpatuloy, pakilagay ang iyong pangalan...',
        itemitem: 'Mga Item',
        item: 'item',
        items: 'mga item',
        empty: 'Sold out',
        orderList: 'Listahan ng order',
        billDetail: 'Detalye ng bill',
        totalPrice: 'Kabuuang presyo',
        totalDisc: 'Kabuuang diskwento',
        platformFee: 'Bayad sa platform',
        parkingFee: 'Bayad sa pag park',
        deliveryFee: 'Bayad sa paghahatid at paghawak',
        takeAwayCharge: "bayad sa takeaway",
        unavailable: "Hindi magagamit",
        toBePaid: 'Babayaran',
        emptyCart: 'Wala pang laman ang shopping cart mo :(',
        orderPlaced: {
            title: 'Nai-place ang order',
            desc: 'Hinihintay ang merchant na kumpirmahin ang iyong order'
        },
        driverFound: {
            title: 'Natagpuan ang driver',
            desc: 'Salamat sa paggamit ng serbisyo ng Maxim'
        },
        driverResto: {
            title: 'nasa lugar ng merchant ang driver',
            desc: 'Dumating na ang driver sa lugar ng merchant'
        },
        merchantCancel: {
            title: 'Nakansela ang iyong order',
            desc: 'Kinansela ng merchant ang order mo'
        },
        merchantconfirm: {
            title: 'Nakumpirma ang iyong order',
            desc: 'Nakumpirma ng merchant ang order mo'
        },
        itemPickedUp: {
            title: 'ipinapadala na ang order mo',
            desc: 'Kasalukuyang ginagawa ang order mo'
        },
        completeStatus: {
            title: 'Nakumpleto na ang order mo',
            desc: 'Salamat sa paggamit ng serbisyo ng Maxim'
        },
        userCancel: {
            title: 'Nakansela ang iyong order',
            desc: 'Kinansela ng user ang order mo'
        },
        buyerName: 'Pangalan ng buyer',
        receiveAt: 'Tinanggap sa',
        status: 'Status',
        paymentType: 'Uri ng pagbabayad',
        cash: 'Cash',
        note: 'Note: ',
        noNote: 'Walang note',
        noteMerchant: 'Note para sa merchant',
        MerchantDetailDiscountContainer: { recommend: 'Inirerekomenda para sa iyo' },
        AddToCart: 'Idagdag sa cart',
        deliverTo: 'I-deliver sa',
        merchantName: 'Pangalan ng Merchant',
        deliveryAddress: 'Address ng Delivery',
        phoneNumber: 'Numero ng Telepono',
        merchantAddress: 'Address ng Merchant',
        additional: 'Dagdag',
    },
    label: { user_name: 'Iyong pangalan: ' },
    placeholder: {
        name: 'Pakilagay ang iyong pangalan',
        searchHome: 'Hanapin ang merchant o item ...',
        searchCategory: 'Hanapin ang merchant',
        noteItemDetail: 'Sumulat ng anumang partikular na detalye rito',
        searchItem: 'Hanapin ang item ...',
        noteMerchant: 'Isulat ang iyong komento o mungkahi para sa merchant'
    },
    alert: {
        orderCancel: 'Nakansela ang order',
        nonPartner: 'Hindi partner',
        partnerPlus: 'Ang halaga ng order ay estimate at puedeng mabago. Pakihingi mong bigyan ng driver ang receipt ng pagbabayad ng produkto.',
        noteNonPartner: 'Hindi official na partner ng Maxim ang merchant na ito, posibleng magbago ang availability ng produkto at accuracy ng presyo at iba sa aktwal na mga kundisyon. Magpatuloy?',
        cart: 'Cart',
        cartEmpty: 'Isa na lang ang natitira para sa item/menu na ito sa iyong cart, kapag binawasan ang dami, nangangahulugan na aalisin ito sa iyong cart.. Magpatuloy?',
        alert: 'Alert',
        noteProcessOrder: 'Pinoproseso ng server ang iyong request, pakihintay... Kung magtagal ang proseso, paki-restart ang iyong app o tingnan ang iyong koneksyon sa Internet.',
        confirmOrder: 'Kumpirmahin ang order',
        noteConfirmOrder: 'Sigurado ka bang gusto mong i-place ang iyong order? Pakitingnan ulit ang order bago kumpirmahin ito.',
        noteCountDeliveryPrice: 'Nagka-error habang kinakalkula ang presyo ng delivery, paki-restart ang app para magpatuloy.',
        noteDeliveryPrice: 'Nagka-error habang nire-retrieve ang iyong lokasyon para kalkulahin ang presyo ng delivery, paki-restart ang app at siguruhing naka-on ang iyong GPS para magpatuloy.',
        notePlatformPrice: 'Nagka-error habang nire-retrieve ang platform fee, paki-restart ang app para magpatuloy.',
        noteNonPartnerReceipt: 'Hindi opisyal na Maxim partner ang merchant na ito, posibleng mag-iba ang availability at mga presyo ng item. Magbayad ayon sa presyong nasa resibo.',
        merchantClose: 'Nakasara ang merchant!'
    },
    helmet: {
        title: {
            start: 'Foods&Goods - Simula',
            Home: 'Foods&Goods',
            Category: 'Foods&Goods - Kategorya ng merchant',
            History: 'Foods&Goods - History',
            DetailCart: 'Foods&Goods - Mga detalye ng cart',
            afterOrder: 'Foods&Goods - Pagkatapos ng Order',
            HistoryDetail: 'Foods&Goods - Mga detalye ng history'
        },
        desc: {
            start: 'Simula',
            Home: 'Foods&Goods',
            Category: 'Listahan ng merchant ayon sa kategorya',
            History: 'Listahan ng mga nauna mong transaksyon',
            DetailCart: 'Mga detalye ng mga item sa iyong cart',
            afterOrder: 'Aking order',
            HistoryDetail: 'Mga detalye ng order'
        }
    },
    topNav: { title: 'Foods&Goods' },
    backTopNav: { History: 'History' },
    loading: 'Naglo-load…',
    data: {
        noDataMerchant: 'Walang natagpuang merchant',
        DataMerchant: 'Natagpuan ang merchant',
        notFound: 'Hindi nahanap'
    },
    bottomNav: {
        Home: 'Home',
        Explore: 'I-explore',
        Promo: 'Promo',
        History: 'History',
        Cart: 'Cart'
    },
    status: {
        Open: 'Bukas',
        Close: 'Sarado',
        case0: 'Hinihintay ang pag-apruba ng store',
        case1: 'Inaprubahan ng store ang order',
        case2: 'Nai-deliver ng driver ng maxim ang order',
        case3: 'Nai-assign ang driver ng Maxim sa order',
        case4: 'Naghihintay ang driver',
        case11: 'Nakumpleto ang order',
        case9: 'Nakansela ang order',
        caseNotFound: 'Hindi natagpuan ang status ng order',
        orderStatus0: 'Naghihintay',
        orderStatus1: 'Inaprubahan ng merchant',
        orderStatus2: 'Na-pick up ng Driver',
        orderStatus3: 'Nai-assign ang driver',
        orderStatus4: 'Naghihintay ang driver',
        orderStatus9: 'Kinansela ng merchant',
        orderStatus11: 'Kumpletuhin',
        orderStatus99: 'Kinansela ng user',
        orderStatusNotFound: 'Hindi natagpuan ang status',
        itemNotFound: 'Walang natagpuang item sa kategoryang ito'
    },
    receiveAt: { statusAccepted: 'Hindi pa natanggap' },
    bottomConfirm: {
        payment: {
            title: '(Mga) digital na paraan ng pagbabayad',
            title2: 'Iba pang (mga) paraan ng pagbabayad',
            cash: 'Cash',
            noncash: 'Non-cash',
            paywith: 'Magbayad gamit ang',
            soon: 'Malapit na'
        },
        btn: { confirm: 'Kumpirmahin at magpatuloy sa order' }
    },
    itemDetails: { note: 'Note para sa merchant' },
    addon: {
        optional: 'Opsyonal',
        qty: 'Dami',
        additm: 'Magdagdag ng item',
        edititm: 'I-update ang item',
        max: 'Pumili ng max',
        edit: 'I-edit',
        require: 'Hingin',
        selectrequire: 'Minimum na pipiliin',
        price: 'Presyo',
        addcustome: 'Magdagdag ng isa pang customer',
        free: 'Libre'
    },
    promo: { listMerchants: 'Ang listahan ng mga merchant' }
};
export default En;